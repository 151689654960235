import React, { useState, useEffect, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import facebook from './../../../assets/images/icons8-facebook-f-36.png';
import twitter from './../../../assets/images/icons8-twitter-36.png';
import pinterest from './../../../assets/images/icons8-pinterest-36.png';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './ShareIntegratePopup.scss';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: '#000000'
    },
});


const ShareIntegratePopup = (props) => {

    const { onClose, open } = props;
    const [isUpFirst, setIsUpFirst] = useState(false);

    const handleClose = () => {
        onClose();
    };

    return (
        <>
            <Dialog className='dialog-container' aria-labelledby="customized-dialog-title" onClose={handleClose} open={open} maxWidth='xl' fullWidth={true} >
                <DialogTitle onClose={handleClose}>
                    <div>
                        <div className="flex-item-center space-between title-width">
                            <div className='share'>
                                SHARE THIS:
                            </div>
                            <IconButton aria-label="close" onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <hr />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className='icon-list'>
                        <div className="facebook-icon-color">
                            <img src={facebook} className='fb-icon' />
                        </div>
                        <div className="twitter-icon-color">
                            <img src={twitter} className='twit-icon' />
                        </div>
                        <div className="pinterest-icon-color">
                            <img src={pinterest} className='pint-icon' />
                        </div>
                    </div>
                    <div className='check-box-list'>
                        <div>
                            <input type='checkbox' /><span className='span1'>Track Link Share</span>
                        </div>
                        <div>
                            <input type='checkbox' /><span className='span1'>Hide left menu</span>
                        </div>
                        {isUpFirst ?
                            <>
                                <div>
                                    <input type='checkbox' /><span className='span1'>Hide right menu</span>
                                </div>
                                <div>
                                    <input type='checkbox' /><span className='span1'>Keep the left menu open</span>
                                </div>
                                <div>
                                    <input type='checkbox' /><span className='span1'>Keep the right menu open</span>
                                </div>
                            </>
                            : null}
                    </div>
                    <hr />
                    <div>
                        <div>
                            <span>More Options</span>
                            {!isUpFirst?
                                <KeyboardArrowDownIcon onClick={()=>setIsUpFirst(!isUpFirst)}/>
                            :
                                <KeyboardArrowUpIcon onClick={()=>setIsUpFirst(!isUpFirst)}/>
                            }
                        </div>
                    </div>
                    <div className='text-field'>
                        <span className='span1'>Text This 360 Gallery</span>
                        <TextField
                            placeholder="(000) 000-0000"
                            fullWidth
                            autoFocus
                            size="small"
                            className="outline-input login-input"
                            variant="outlined"
                        />
                        {/* <IconButton className="btn-right">
                      <NavigateNextIcon />
                    </IconButton> */}
                    </div>
                    <div className='text-field'>
                        <span className='span1'>360 Gallery Share Link</span>
                        <TextField
                            placeholder="https://bit.ly/3uFnron"
                            fullWidth
                            autoFocus
                            size="small"
                            className="outline-input login-input"
                            variant="outlined"
                        />
                        {/* <IconButton className="btn-right">
                      <NavigateNextIcon />
                    </IconButton> */}
                    </div>
                    <div className='text-field'>
                        <span className='span1'>Email This 360 Gallery</span>
                        <TextField
                            placeholder="example@email.com"
                            fullWidth
                            autoFocus
                            size="small"
                            className="outline-input login-input"
                            variant="outlined"
                        />
                        {/* <IconButton className="btn-right">
                      <NavigateNextIcon />
                    </IconButton> */}
                    </div>
                    <hr />
                    <div>
                        <div>
                            <span>Close</span>
                        </div>
                    </div>
                    <div className='text-field'>
                        <span className='span1'>360 Gallery White Label Link</span>
                        <TextField
                            placeholder="(000) 000-0000"
                            fullWidth
                            autoFocus
                            size="small"
                            className="outline-input login-input"
                            variant="outlined"
                        />
                        {/* <IconButton className="btn-right">
                      <NavigateNextIcon />
                    </IconButton> */}
                    </div>
                    <div className='text-field'>
                        <span className='span1'>Embed this 360 gallery</span>
                        <TextareaAutosize
                            maxRows={4}
                            aria-label="maximum height"
                            placeholder="Maximum 4 rows"
                            defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididuntut labore et dolore magna aliqua."
                            style={{ width: 650 }}
                        />
                        {/* <IconButton className="btn-right">
                      <NavigateNextIcon />
                    </IconButton> */}
                    </div>
                    <div className='text-field'>
                        <span className='span1'>Link To This 360 Gallery As A Popup Modal</span>
                        <TextareaAutosize
                            maxRows={4}
                            aria-label="maximum height"
                            placeholder="Maximum 4 rows"
                            defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididuntut labore et dolore magna aliqua."
                            style={{ width: 650 }}
                        />
                        {/* <IconButton className="btn-right">
                      <NavigateNextIcon />
                    </IconButton> */}
                    </div>
                    <div className='check-box-list'>
                        <div>
                            <input type='checkbox' /><span className='span1'> Add "Click to View" Layer To Embed Script</span>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ShareIntegratePopup;