/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import LinearProgress from '@mui/material/LinearProgress';
import Logo from './../../assets/images/vm-logo-small.png';

import './ProgImage.scss';

const ProgImage = ({ height, ...props }) => {
  // src="https://devapi.demo2clients.com/scenes/Summerfield%20Estates/01Lobby-scene.jpeg"
  // placeholder="https://devapi.demo2clients.com/scenes/Summerfield%20Estates/preview.jpeg"

  return (
    <div className="prog-image-wrapper-108">
      <ProgressiveImage
        src={props.src}
        placeholder={props.preview ? props.preview : Logo}
      >
        {(src, loading) => (
          <div className="image-wrapper" style={{ height: height ? height : 'none' }}>
            {loading && props.showLoader && <LinearProgress color="secondary" />}
            <img src={src} className={loading ? 'loading-image' : 'image-loaded'} />
            <canvas className={`progressive-canvas ${loading ? '' : 'loaded'}`} height="15" width="25"></canvas>
          </div>
        )}
      </ProgressiveImage>
    </div>
  )
}

export default ProgImage;