import React, { useState, useEffect } from "react";
import { getGCSSignedURL, getSceneDetails360 } from "./../../services/ApiService";
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import VmcmsPanoroma from "../../components/Pannellum/VmcmsPanoroma";

const Scene360 = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [sceneDetail, setSceneDetail] = useState({});
    const { id } = useParams();
    const [tourConfig, setTourConfig] = useState({
        autoRotate: 0,
        autoLoad: true,
        pitch: 0,
        hfov: 120,
        haov: props.isFlatView ? 120 : 360,
        vaov: props.isFlatView ? 110 : 180,
        showZoomCtrl: true,
        author: "VMCMS",
        compass: false,
        draggable: props.isFlatView ? false : true,
        type: "equirectangular",
        zoom: 0,
        flatView: false,
        hotSpotDebug: false,
        showControls: props.hideControls ? false : true,
        disableKeyboardCtrl: true,
        styles: {
            // minWidth:'100%',
            // maxWidth:'100%',
            width: `100%`,
            height: "400px",
            background: "#000000"
        },
    });


    useEffect(() => {
        getSceneDetails360ByEncrypt(id);
    }, []);

    // get tour 360 by encrypted tourId
    const getSceneDetails360ByEncrypt = (id) => {
        setIsLoading(true);
        const payload = {
            encryptedData: id,
        }
        getSceneDetails360(payload).then(async (res) => {
            const data = res.data && res.data.data ? res.data.data : null;
            if (data) {
                setSceneDetail(data);
            }
        })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);

            });
    };

    return (
        <>
            {isLoading ? <div className="page-loader"><CircularProgress color='primary' size={40} /> </div> :
                <div id='container' className="tour-360-page">
                    <div>
                        {sceneDetail.url && <VmcmsPanoroma
                            editingNotes={''}
                            comments={[]}
                            sceneCommentsRef={{}}
                            id="scene-pan-viewer-2"
                            className={'show'}
                            sceneDetail={sceneDetail}
                            imgPath={sceneDetail.url}
                            selectedScene={{ ...sceneDetail }}
                        // onTourUpdated={(tourConfigUpdate) => {
                        //     if (Object.keys(tourConfig).length === 0) {
                        //         setTourConfig({ ...tourConfigUpdate });
                        //         // localStorage.setItem("config", JSON.stringify({ ...tourConfigUpdate }));
                        //     } else if (!tourConfig.scenes?.hasOwnProperty(sceneDetail._id)) {
                        //         let newTourConfig = tourConfig;
                        //         newTourConfig.scenes = {
                        //             ...tourConfig.scenes,
                        //             [tourConfigUpdate.scenes[sceneDetail._id].sceneId]: tourConfigUpdate.scenes[sceneDetail._id]
                        //         }
                        //         setTourConfig({ ...newTourConfig });
                        //         // localStorage.setItem("config", JSON.stringify({ ...newTourConfig }));
                        //     } else {
                        //         let newTourConfig = tourConfig;
                        //         Object.keys(newTourConfig.scenes).forEach(sceneId => {
                        //             if (sceneId == sceneDetail._id) {
                        //                 newTourConfig.scenes[sceneId] = tourConfigUpdate.scenes[sceneDetail._id]
                        //             }
                        //         })
                        //         setTourConfig({ ...newTourConfig });
                        //         // localStorage.setItem("config", JSON.stringify({ ...newTourConfig }));
                        //     }
                        // }}
                        />}
                    </div>
                </div>
            }
        </>
    );
};

export default Scene360;