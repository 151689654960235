import { Slider } from '@mui/material';
import React from 'react'
import "./viewController.scss";
import FontSize from "../../assets/fontSize.svg";

const ADAViewController = ({ configureVisual, onConfigureChangeHandler, }) => {

  const handlerChange = (e) => {
    onConfigureChangeHandler("fontSize", e.target.value);
  };

  const increaseFontSize = () => {
    onConfigureChangeHandler('fontSize', configureVisual.fontSize + 1 > 5 ? 5 : configureVisual.fontSize + 1)
  };

  const decreaseFontSize = () => {
    onConfigureChangeHandler('fontSize', configureVisual.fontSize - 1 < 1 ? 1 : configureVisual.fontSize - 1)
  };


  return (
    <div className='viewController' tabIndex='10001' onKeyUp={(e) => {
      if (e.code === "ArrowUp") {
        increaseFontSize();
      } else if (e.code === "ArrowDown") {
        decreaseFontSize();
      }
    }}>
      <img src={FontSize} width={24} height={24} alt="Font Size" />
      <div className='viewController-slider'>
        <Slider aria-labelledby="vertical-slider" orientation="vertical" max={5} min={1} value={configureVisual.fontSize} marks valueLabelDisplay="on" step={1} className="zoom-slider" onChange={handlerChange} color="secondary" />
      </div>
    </div>
  )
}

export default ADAViewController