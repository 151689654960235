import React, { useState, useEffect } from 'react';
import pannellum from './library/pan-lib'
import './Pannellum.scss';

const VmcmsPanoroma = ({ onlyOneTimeRender = false, ...props }) => {
    const [addedHotspot, setAddedHotSpot] = useState(0);
    const [panEditor, setPanEditor] = useState();
    const [selectedHotsot, setSelectedHotsot] = useState({});
    const [oneTimeRen, setOneTimeRen] = useState(false);
    const config = {
        autoRotate: 0,
        autoLoad: true,
        // pitch: 0,
        hfov: 120,
        // haov: props.isFlatView ? 120 : 360,
        // vaov: props.isFlatView ? 110 : 180,
        showZoomCtrl: true,
        author: 'VMCMS',
        compass: false,
        draggable: props.isFlatView ? false : true,
        type: 'equirectangular',
        zoom: 0,
        flatView: true,
        // hotSpots: props.hotspots ? props.hotspots : [],
        hotSpotDebug: false,
        firstScene: props.sceneDetail._id,
        scene: props.sceneDetail._id,
        sceneId: props.sceneDetail._id,
        scenes: {
            [props.sceneDetail._id]: {
                sceneId: props.sceneDetail._id,
                pitch: 0,
                haov: props.isFlatView ? 360 : 360,
                vaov: props.isFlatView ? 180 : 180,
                type: "equirectangular",
                imageSource: props.imgPath,
                hotSpots: [],
            }
        }
        // imageSource: props.imgPath,
    };

    const defaultPitch = 0;
    const defaultYaw = 0;
    let dragHotspot = {};

    const onPanoramaLoaded = (panEditor) => {
        if (props.isViewSelector) {
            panEditor.on("mouseup", (event) => {
                var coords = panEditor.mouseEventToCoords(event);
                const view = {
                    pitch: coords[0],
                    yaw: coords[1],
                    targetPitch: panEditor.getConfig().pitch,
                    targetYaw: panEditor.getConfig().yaw,
                    targetHfov: panEditor.getConfig().hfov,
                };
                emitViewSelected(view);
                // console.log("Pitch: " + coords[0] + ", Yaw: " + coords[1] + ", Center Pitch: " + panEditor.getConfig().pitch + ", Center Yaw: " + panEditor.getConfig().yaw + ", HFOV: " + panEditor.getConfig().hfov);
            });
        }
        // initHotspotDraggable(panEditor);
        // saveSceneConfig();
        const tourConfig = panEditor.getConfig();
        if (props.onTourUpdated) {
            props.onTourUpdated(tourConfig);
        }
        emitViewSelected();
    };

    const emitViewSelected = (view) => {
        if (props.viewSelected) {
            props.viewSelected(view);
        }
    };

    useEffect(() => {
        if ((oneTimeRen && onlyOneTimeRender)) {
            return;
        }
        panEditor && panEditor.destroy();
        setPanEditor({})
        createUpdateScene(props.sceneDetail);
        // console.log('config',config)
        // const panoEditor = pannellum.viewer(props.id, config);

        // setPanEditor(panoEditor);

        // panoEditor.on("load", () => {
        //   onPanoramaLoaded(panoEditor);
        // });
    }, [props.imgPath, props.hotspotArray])

    const selectedHotsotComment = () => {
        const HSArray = panEditor.getConfig().scenes[props.sceneDetail._id].hotSpots
        const newHS = HSArray.find((hs) => hs.id === props.selectedCommentHotspot.id)

        let items = document.querySelectorAll(".pnlm-hotspot-base");
        items.forEach(function (item) {
            item.classList.remove("center-comment-selected");
        });
        if (newHS && newHS.div) {
            const hotspotDom = newHS.div;
            if (hotspotDom && hotspotDom.classList.contains("pnlm-hotspot-base")) {
                hotspotDom.classList.add("center-comment-selected");
            }
        }
    }
    useEffect(() => {
        if (!props.selectedCommentHotspot) {
            return;
        }
        if (panEditor && props.sceneDetail._id) {
            let panoEditor = panEditor.loadScene(props.sceneDetail._id, props.selectedCommentHotspot.pitch || 0, props.selectedCommentHotspot.yaw || 0, props.selectedCommentHotspot.hfov || 120);
            panoEditor.on("load", () => {
                selectedHotsotComment(props.selectedCommentHotspot)
            });
            setPanEditor(panoEditor);
        }
    }, [props.selectedCommentHotspot])

    const onAddHotsopt = (pitch, yaw, id, isComment = false, action = "scene") => {
        let items = document.querySelectorAll(".pnlm-hotspot-base");
        items.forEach(function (item) {
            item.removeEventListener("dragend", onDragEndHotspot);
        });

        const sceneId = props.sceneDetail._id;

        panEditor.addHotSpot(
            {
                id: id ? id : `hotspot-${Date.now()}`,
                pitch: panEditor.getConfig().pitch ? panEditor.getConfig().pitch : defaultPitch,
                yaw: panEditor.getConfig().yaw ? panEditor.getConfig().yaw : defaultYaw,
                type: "info",
                icon_type: `${!isComment ? 'commentRequest' : "commentRequest"}`,
                cssClass: "custom-hotspot",
                createTooltipArgs: {
                    type: `${!isComment ? 'commentRequest' : "commentRequest"}`,
                    action: action,
                    title: "",
                    message: "",
                },
                createTooltipFunc: generateTooltip,
                clickHandlerFunc: (event, hotspot) => {
                },
                // mousedownFunc: (event, hotspot) => {
                //   let panEditor2 = panEditor.getConfig()
                //   if (panEditor2.hotSpots.length) {
                //     hotspot = panEditor.getConfig().hotSpots.find((e) => e.id == hotspot.id)
                //   }
                //   onSelectHotspot(event, hotspot);
                // },
                isPreview: false
            },
            sceneId,
            (hotspot) => {
                initHotspotDraggable(panEditor, hotspot);
                saveSceneConfig(panEditor);
                onSelectHotspot('', hotspot);
            }
        );
    };

    const onDragEndHotspot = (event, panEditor) => {
        const dragHsDom = event.target;
        const dragHsId = dragHsDom.getAttribute('hotspot-id');
        if (panEditor) {
            let panEditor2 = panEditor.getConfig()
            if (panEditor2.hotSpots.length) {
                dragHotspot = panEditor.getConfig().hotSpots.find((e) => e.id == dragHsId)
            }
            if (dragHotspot.id === dragHsId) {
                var coords = panEditor.mouseEventToCoords(event);
                dragHotspot.pitch = coords[0];
                dragHotspot.yaw = coords[1];
                panEditor.moveHotSpot(dragHotspot, () => {
                    saveSceneConfig(panEditor);
                    emitHotspotSelected(dragHotspot, true);
                    let items = document.querySelectorAll(".pnlm-hotspot-base");
                    items.forEach(function (item) {
                        if (item.getAttribute("hotspot-id") !== dragHotspot.id) {
                            item.classList.remove("hot-spot-selected");
                        } else {
                            item.classList.add("hot-spot-selected");
                            // item.classList.toggle("hot-spot-selected");
                        }
                    });
                });
            }
        }
    };

    const generateTooltip = (hotSpotDiv, args) => {
        if (!args.type) {
            return;
        }
        const findIndex = props.comments.findIndex(ele => ele._id === args.commentID);

        if (args.type === 'commentRequest' && (args.title === "" && args.message === "")) {
            hotSpotDiv.classList.remove("custom-tooltip");
            hotSpotDiv.innerHTML = "";
        } else {
            hotSpotDiv.classList.add("custom-tooltip");
            hotSpotDiv.innerHTML = "";
            var span = document.createElement("span");

            if (args.type === 'commentRequest') {
                // hotSpotDiv.classList.add("commentRequest");
                if (args.message !== "") {
                    span.innerHTML = `
            <p class="tooltip-desc">${args.message}</p>
        `;
                }
            } else {
                if (args.action === "scene" && (args.title !== "" || args.message !== "")) {
                    span.innerHTML = `
            <p class="tooltip-title">${args.title}</p>
            <p class="tooltip-desc">${args.message}</p>
          `;
                }
            }
            if (findIndex !== -1 && props.comments[findIndex].commentStatus === 'Done') {
                hotSpotDiv.remove();
                return;
            } else {
                hotSpotDiv.appendChild(span);
                span.style.width = span.scrollWidth - 20 + "px";
                span.style.marginLeft = -(span.scrollWidth - hotSpotDiv.offsetWidth) / 2 + "px";
                span.style.marginTop = -span.scrollHeight - 12 + "px";
                setAddedHotSpot(prev => prev + 1);
            }
        }
    };

    const saveSceneConfig = (panEditor) => {
        if (panEditor) {
            const tourConfig = panEditor.getConfig();
            if (props.onTourUpdated) {
                props.onTourUpdated(tourConfig);
            }
        }
    };

    const initHotspotDraggable = (panEditor, hotspot) => {
        const container = document.querySelector(`#${props.id}`);
        if (container) {
            let items = container.querySelectorAll(".pnlm-hotspot-base");
            items.forEach(function (item) {
                item.removeEventListener("dragend", (e) => onDragEndHotspot(e, panEditor), false);
            });
            items.forEach(function (item) {
                item.classList.remove("center-comment-selected");
            });

            items.forEach(function (item) {
                if (hotspot && item.getAttribute("hotspot-id") === hotspot.id) {
                    item.setAttribute("draggable", true);
                    item.addEventListener("dragend", (e) => onDragEndHotspot(e, panEditor));
                }
            });
        }
    };

    const emitHotspotSelected = (hotspot, isSelected) => {
        if (props.hotSpotSelected) {
            if (hotspot?.createTooltipArgs?.type === 'commentRequest' && !isSelected) {
                setSelectedHotsot(hotspot);
                props.hotSpotSelected(hotspot, !isSelected);
            } else {
                setSelectedHotsot(isSelected ? hotspot : {});
                props.hotSpotSelected(hotspot, isSelected);
            }
        }
    };

    const onSelectHotspot = (event, hotspot) => {
        if (hotspot && hotspot.div) {
            const hotspotDom = hotspot.div;
            dragHotspot = { ...hotspot, dragstart: true };
            if (hotspotDom && hotspotDom.classList.contains("pnlm-hotspot-base")) {
                hotspotDom.classList.toggle("hot-spot-selected");
                const isSelected = hotspotDom.classList.contains("hot-spot-selected");
                emitHotspotSelected(hotspot, isSelected);
            }

            let items = document.querySelectorAll(".pnlm-hotspot-base");
            items.forEach(function (item) {
                if (item.getAttribute("hotspot-id") !== hotspot.id) {
                    item.classList.remove("hot-spot-selected");
                }
            });
        }
    };

    const createUpdateScene = (selectedScene, isAutoLoadScene = true) => {
        if (!selectedScene || (oneTimeRen && onlyOneTimeRender)) {
            return;
        }
        setOneTimeRen(true);
        let loadconfig = {}
        if (Object.keys(config.scenes).length !== 0 && config.scenes[selectedScene._id]) {
            loadconfig = {
                ...config,
                firstScene: selectedScene._id,
                scene: selectedScene._id,
                sceneId: selectedScene._id,
                scenes: {
                    ...config.scenes,
                    [selectedScene._id]: {
                        sceneId: selectedScene._id,
                        pitch: 0,
                        haov: props.isFlatView ? 360 : 360,
                        vaov: props.isFlatView ? 180 : 180,
                        type: "equirectangular",
                        imageSource: props.imgPath,
                        title: selectedScene.title,
                        hotSpots: props.hotspotArray ?
                            props.hotspotArray
                            : [],
                        customerId: selectedScene?.shootId?.customerId?._id,
                    }
                }
            }
            Object.keys(loadconfig.scenes).map(sceneId => {
                loadconfig.scenes[sceneId].hotSpots.map(hotspot => {
                    hotspot.createTooltipFunc = generateTooltip;
                })
            })
        }
        else {
            // Object.keys(config.scenes).map(sceneId => {
            //   config.scenes[sceneId].hotSpots.map(hotspot => {
            //     // hotspot.mousedownFunc = (e, hotspot) => onSelectHotspot(e, hotspot);
            //     console.log(hotspot)
            //     hotspot.createTooltipFunc = generateTooltip;
            //   })
            // })
            loadconfig = {
                ...config,
                firstScene: selectedScene._id,
                scene: selectedScene._id,
                sceneId: selectedScene._id,
                scenes: {
                    ...config.scenes,
                    [selectedScene._id]: {
                        sceneId: selectedScene._id,
                        pitch: 0,
                        haov: props.isFlatView ? 360 : 360,
                        vaov: props.isFlatView ? 180 : 180,
                        type: "equirectangular",
                        imageSource: props.imgPath,
                        title: selectedScene.title,
                        hotSpots: props.hotspotArray ?
                            props.hotspotArray
                            : [],
                        customerId: selectedScene.shootId.customerId._id,
                    }
                }
            }
            Object.keys(loadconfig.scenes).map(sceneId => {
                loadconfig.scenes[sceneId].hotSpots.map(hotspot => {
                    hotspot.createTooltipFunc = generateTooltip;
                })
            })
        }
        const panoEditor = pannellum.viewer(props.id, loadconfig);
        setPanEditor(panoEditor);

        panoEditor.on("load", () => {
            onPanoramaLoaded(panoEditor);
        });
    }

    const savePanoImage = () => {
        var img = panEditor.getRenderer().render(panEditor.getConfig().pitch / 180 * Math.PI,
            panEditor.getConfig().yaw / 180 * Math.PI,
            panEditor.getConfig().hfov / 180 * Math.PI,
            { 'returnImage': true });
        var link = document.createElement('a');
        link.download = props.sceneDetail?.title ? `${props.sceneDetail.title}.jpg` : 'Pano-image.jpeg';
        link.href = img;
        link.click();
        console.log(img);
    }

    props.sceneCommentsRef.onAddHotsopt = onAddHotsopt;
    props.sceneCommentsRef.savePanoImage = savePanoImage;

    useEffect(() => {

        if (!props.selectedScene) {
            return;
        }
        if (panEditor && props.selectedScene.id) {
            // , props.selectedScene.pitch || 0,props.selectedScene.yaw||0,props.selectedScene.hfov||100
            let panoEditor = panEditor.loadScene(props.selectedScene.id, props.selectedScene.pitch || 0, props.selectedScene.yaw || 0, props.selectedScene.hfov || 120);
            panoEditor.on("load", () => {
                // onPanoramaLoaded(panoEditor);
            });
            // panoEditor.resize()
            setPanEditor(panoEditor);
        }
    }, [props.selectedScene]);

    return (
        <div className="pannellum-wrapper">
            <div className="tour-360-display">
                <div id={props.id}></div>
            </div>
        </div>
    )
}

export default VmcmsPanoroma;
