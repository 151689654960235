import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import ldConfigs from './launchdarkly-config';

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: ldConfigs[process.env.REACT_APP_FIREBASE_CONFIG],
    user: {
      key: Math.random()
        .toString(36)
        .substring(7),
      anonymous: true,
    },
    options: { /* ... */ },
  });

ReactDOM.render(
  <LDProvider>
    <App />
  </LDProvider>,
  document.getElementById('root')
);

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
