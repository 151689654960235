import React, { useState, useEffect, useRef } from "react";
import {
  getGCSSignedURL,
  getTourDetails360,
  getTourSceneDetails360,
} from "./../../services/ApiService";
import { useParams } from "react-router-dom";
import VmtttPanoromaViewer from "./../../components/Pannellum/VmtttPanoromaViewer";
import IconButton from "@mui/material/IconButton";
import DehazeIcon from "@mui/icons-material/Dehaze";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import VrpanoIcon from "@mui/icons-material/Vrpano";
import ShareIcon from "@mui/icons-material/Share";
import ShareIntegratePopup from "./ShareIntegratePopup/ShareIntegratePopup";
import "./Tour360.scss";
import CircularProgress from "@mui/material/CircularProgress";
// import 'aframe-animation-component';
// import 'aframe-particle-system-component';
// import 'babel-polyfill';
import Switch from "@material-ui/core/Switch";
import ADAViewController from "../ADAviewController/ADAViewController";
import ADASoundController from "../ADASoundController/ADASoundController";
import { Tooltip } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";

const Tour360 = ({ configureVisual, onConfigureChangeHandler, ...props }) => {
  const flags = useFlags();
  const audioRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tourConfig, setTourConfig] = useState({});
  const [selectedScene, setSelectedScene] = useState({});
  const [tourScenes, setTourScenes] = useState([]);
  const [isShareAndIntegarateOpen, setIsShareAndIntegarateOpen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { id, sceneId } = useParams();
  const defaultConfig = {
    autoRotate: 0,
    autoLoad: true,
    pitch: 0,
    hfov: 120,
    haov: props.isFlatView ? 120 : 360,
    vaov: props.isFlatView ? 110 : 180,
    showZoomCtrl: true,
    author: "VMCMS",
    compass: false,
    draggable: props.isFlatView ? false : true,
    type: "equirectangular",
    zoom: 0,
    flatView: false,
    hotSpotDebug: false,
    showControls: props.hideControls ? false : false,
    disableKeyboardCtrl: true,
    styles: {
      width: `100%`,
      height: "400px",
      background: "#000000",
    },
  };

  useEffect(() => {
    getTourDetails360ByEncrypt(id);
  }, [id]);

  //get GCS Signed URL for scenes
  const getURLForScene = async (scenesData) => {
    let payload = {};
    let sceneArray = [];
    scenesData.forEach((scene) => {
      sceneArray.push({
        sceneId: scene.sceneId,
        sceneUrl: decodeURIComponent(scene.imageSource),
        customerId: scene.customerId._id || scene.customerId || scene.localSceneCustomerId,
      });
    });
    payload = {
      scenes: sceneArray,
    };

    let newSceneList = [...scenesData];
    const res = await getGCSSignedURL(payload);
    // .then((res) => {
    const data = res && res.data ? res.data : {};
    let resSceneArray = data.url;

    await Promise.all(
      newSceneList.map(async (scene) => {
        let url = resSceneArray.find((ele) => ele.id === scene.sceneId).signedUrlMedium;
        let signedUrlSmall = resSceneArray.find((ele) => ele.id === scene.sceneId).signedUrlSmall;
        scene.signedUrlSmall = signedUrlSmall;
        scene.signedUrlMedium = resSceneArray.find(
          (ele) => ele.id === scene.sceneId
        ).signedUrlMedium;
        scene.signedUrlLarge = resSceneArray.find((ele) => ele.id === scene.sceneId).signedUrlLarge;
        scene.imageSource = url;
        scene.hotSpots =
          scene.hotSpots.length > 0
            ? await getURLForHotspotScene(scene, scene.hotSpots, scene.customerId)
            : [];
      })
    );

    return convertArrayToObject(newSceneList, "sceneId");
  };

  //get GCS Signed URL for Hotspot scene
  const getURLForHotspotScene = async (scene, hotspots, cId) => {
    let payload = {};
    let hotspotsArray = [];

    for (const htScene of hotspots) {
      if (
        htScene &&
        htScene.createTooltipArgs &&
        htScene.createTooltipArgs.scene &&
        htScene.createTooltipArgs.scene.id !== scene.sceneId
      ) {
        hotspots.forEach((index) => {
          if (
            index?.createTooltipArgs?.scene &&
            (index?.createTooltipArgs?.scene?.url || index?.createTooltipArgs?.scene?.urlMedium)
          ) {
            hotspotsArray.push({
              sceneId: index.createTooltipArgs.scene._id,
              sceneUrl:
                decodeURIComponent(index.createTooltipArgs.scene.url) ||
                decodeURIComponent(index.createTooltipArgs.scene.urlMedium),
              customerId:
                index.createTooltipArgs.scene.customerId?._id ||
                index.createTooltipArgs.scene.customerId ||
                index.createTooltipArgs.scene.localSceneCustomerId ||
                cId,
            });
          }
        });
        if (hotspotsArray.length > 0) {
          payload = {
            scenes: hotspotsArray,
          };
          let newHotspots = [...hotspots];
          const res = await getGCSSignedURL(payload);
          const data = res && res.data ? res.data : {};
          let resHotspotsArray = data.url;

          newHotspots.forEach((index) => {
            if (index?.createTooltipArgs?.scene) {
              index.createTooltipArgs.scene.url = resHotspotsArray.find(
                (ele) => ele.id === index.createTooltipArgs.scene._id
              ).signedUrl;
              index.createTooltipArgs.scene.urlMedium = resHotspotsArray.find(
                (ele) => ele.id === index.createTooltipArgs.scene._id
              ).signedUrlMedium;
            }
          });
          return newHotspots;
        } else {
          return [];
        }
      } else {
        return hotspots;
      }
    }
  };

  //conver array into obj
  const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };

  // get tour 360 by encrypted tourId
  const getTourDetails360ByEncrypt = (id) => {
    setIsLoading(true);
    let payload = {
      encId: id,
    };
    let apiCall = getTourDetails360;
    if (sceneId) {
      apiCall = getTourSceneDetails360;
      payload = {
        tourId: id,
        sceneId: sceneId,
      };
    }
    apiCall(payload)
      .then(async (res) => {
        const data = res.data && res.data.data ? res.data.data : null;
        if (data) {
          const newTourConfig = JSON.parse(data);

          if (data !== "false" && data !== null && data !== "") {
            const scenesData = newTourConfig.length !== 0 && newTourConfig;
            setPreviewMode(scenesData[0].previewMode);
            const scenes = await getURLForScene(scenesData);
            if (scenes) {
              const config = {
                ...defaultConfig,
                scenes,
              };
              setTourConfig(config);
              if (sceneId) {
                let findIndex = Object.keys(config.scenes).findIndex(
                  (key) => config.scenes[key].sceneId === sceneId
                );
                if (findIndex < 0) {
                  findIndex = 0;
                }
                Object.keys(config.scenes).length != 0
                  ? setSelectedScene(config.scenes[Object.keys(config.scenes)[findIndex]])
                  : setSelectedScene({});
              } else {
                let findIndex = Object.keys(config.scenes).findIndex(
                  (key) => config.scenes[key].selectedDefault
                );
                if (findIndex < 0) {
                  findIndex = 0;
                }
                Object.keys(config.scenes).length != 0
                  ? setSelectedScene(config.scenes[Object.keys(config.scenes)[findIndex]])
                  : setSelectedScene({});
              }
              if (Object.keys(config.scenes).length != 0) {
                const sceneList =
                  config.scenes &&
                  Object.keys(config.scenes).map((sceneId) => {
                    return config.scenes[sceneId];
                  });
                setTourScenes(sceneList);
              }
            } else {
              const config = {
                ...defaultConfig,
                scenes: {},
              };
              setTourConfig(config);
              setSelectedScene({});
            }
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        //   const message = (err && err.data) ? err.data.error || err.data.message : Enums.INTERNAL_SERVER_ERROR;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //previous tour scene
  const prevTourScene = () => {
    const currentIndex = tourScenes.findIndex((ele) => ele.sceneId === selectedScene.sceneId);
    setSelectedScene(tourScenes[currentIndex - 1]);
  };

  //next tour scene
  const nextTourScene = () => {
    const currentIndex = tourScenes.findIndex((ele) => ele.sceneId === selectedScene.sceneId);
    setSelectedScene(tourScenes[currentIndex + 1]);
  };

  // //dispaly tour in full screen
  // const fullScreenDispaly = () =>{
  //   var element = document.querySelector("#container");
  //   element.requestFullscreen()
  //   .then(function() {
  //     // element has entered fullscreen mode successfully
  //   })
  // }

  //dispaly tour in full screen and exit full screen
  const fullScreenDispaly = () => {
    var isInFullScreen =
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null);

    var docElm = document.documentElement;
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
        setIsFullScreen(true);
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullScreen(false);
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  //Share and Integrate Popup Open
  const handleShareIntegratePopupOpen = () => {
    setIsShareAndIntegarateOpen(true);
  };

  //Share and Integrate Popup Close
  const handleShareIntegratePopupClose = () => {
    setIsShareAndIntegarateOpen(false);
  };

  const currentIndex = tourScenes.findIndex((ele) => ele.sceneId === selectedScene.sceneId);

  const [adaMode, setAdaMode] = useState(false);
  const [vrMode, setVRMode] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);

  const toggleADAMode = () => {
    setAdaMode((prev) => !prev);
  };

  const toggleVRMode = () => {
    setVRMode((prev) => !prev);
  };

  const togglePreviewMode = () => {
    setPreviewMode((prev) => !prev);
  };

  return (
    <>
      {isLoading ? (
        <div className={`page-loader`}>
          <CircularProgress color="primary" size={40} />{" "}
        </div>
      ) : (
        <div id="container" className={`tour-360-page  font-Size-${configureVisual.fontSize}`}>
          {/* <div className="full-screen-viewer"> */}
          {/* <div className="tour-360-header">
                      <div>
                          <IconButton>
                              <DehazeIcon className="dehaze-icon-color" />
                          </IconButton>
                          <IconButton>
                              <QrCodeScannerIcon className="qrCodeScanner-icon-color" />
                          </IconButton>
                          <span className="tour-name-span-1">Customer Name / Tours Name / Tour Name</span>
                      </div>
                  </div> */}

          {/* <div className="navigation">
                    <button className="navigate-before-button" disabled={currentIndex === 0} onClick={()=>prevTourScene()}>
                      <span>
                        <NavigateBeforeIcon className="navigate-before-icon-color" />
                      </span>
                      <span className="previous-span">Previous</span>
                    </button>

                    <button className="navigate-next-button" disabled={tourScenes.length <= currentIndex + 1} onClick={()=>nextTourScene()}>
                      <span className="next-span">Next</span>
                      <span className="navigate-next-icon-color">
                        <NavigateNextIcon />
                      </span>
                    </button>
                  </div> */}
          {flags?.adaAndVrToggle && (
            <div className="functions-keys">
              <div className="switch-modes">
                <p>ADA Mode</p>
                <Tooltip title={adaMode ? "ON" : "OFF"}>
                  <Switch
                    checked={adaMode}
                    onChange={toggleADAMode}
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </Tooltip>
              </div>
              {adaMode && (
                <>
                  <ADASoundController
                    isPlaying={isPlaying}
                    audioRef={audioRef}
                    setIsPlaying={setIsPlaying}
                  />
                  <ADAViewController
                    configureVisual={configureVisual}
                    onConfigureChangeHandler={onConfigureChangeHandler}
                  />
                  {selectedScene.altText && (
                    <Tooltip title={selectedScene.altText}>
                      <p tabIndex={"1000001"} className="ada-alttext">
                        Alt
                      </p>
                    </Tooltip>
                  )}
                </>
              )}
              <div className="switch-modes">
                <p>VR Mode</p>
                <Tooltip title={vrMode ? "ON" : "OFF"}>
                  <Switch
                    checked={vrMode}
                    onChange={toggleVRMode}
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </Tooltip>
              </div>
              {/* <div className="switch-modes">
              <p>Preview Mode</p>
              <Tooltip title={vrMode ? 'ON' : "OFF"}>
                <Switch
                  checked={previewMode}
                  onChange={togglePreviewMode}
                  name="checkedA"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </Tooltip>
            </div> */}
            </div>
          )}
          <div>
            {Object.keys(tourConfig).length !== 0 && (
              <VmtttPanoromaViewer
                id="vmttt-hotspot-preview"
                tourConfig={tourConfig}
                hotspots={[]}
                audioRef={audioRef}
                previewMode={previewMode}
                isFlatView={false}
                selectedScene={selectedScene}
                setSelectedScene={setSelectedScene}
              />
            )}
          </div>

          {/* <div className="fullscreen-vr-share">
                    <button className="fullscreen-button-new" onClick={()=>fullScreenDispaly()}>
                      <span className="fullscreen-span-1">Full Screen</span>
                      <span className="fullscreen-span-2">
                        {!isFullScreen ? <FullscreenIcon/> : <FullscreenExitIcon/>}
                      </span>
                    </button>

                    <button className="vr-button-new" >
                      <span className="vr-span-1">Launch VR</span>
                      <span className="vr-span-2">
                        <VrpanoIcon/>
                      </span>
                    </button>

                    <button className="share-button-new" >
                      <span className="share-span-1">Share &#38; Integrate</span>
                      <span className="share-span-2">
                        <ShareIcon/>
                      </span>
                    </button>
                  </div> */}

          {/* </div> */}
          {isShareAndIntegarateOpen ? (
            <ShareIntegratePopup
              // headerTitle={selectedValue.title}
              open={isShareAndIntegarateOpen}
              onClose={handleShareIntegratePopupClose}
            ></ShareIntegratePopup>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Tour360;
