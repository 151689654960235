import React, { useState, useRef, useEffect, useContext } from "react";
import pannellum from "./library/pan-lib";
import "./Pannellum.scss";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import GetAppIcon from "@mui/icons-material/GetApp";
import { PDFReader } from "reactjs-pdf-reader";
import ProgImage from "./../ProgImage/ProgImage";
import axios from "axios";
import fileDownload from "js-file-download";
import GlobalState from "../../GlobalState";
import urlParser from "js-video-url-parser";
import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, EditorState } from "draft-js";

const VmtttPanoromaViewer = ({ audioRef, previewMode, ...props }) => {
  const [panEditor, setPanEditor] = useState();
  const [addedHotspot, setAddedHotSpot] = useState(0);
  const pannellumRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [app] = useContext(GlobalState);

  /** Preview Mode States */
  const [previewData, setPreviewData] = useState({
    show: false,
    src: null,
    type: null,
    id: null,
  });

  const resetPreviewData = () => {
    setPreviewData({
      show: false,
      src: null,
      type: previewData.type,
      id: null,
    });
  };

  const downloadCurrentData = async () => {
    setIsLoading(true);
    const mimetype =
      previewData.type === "video"
        ? ".mp4"
        : previewData.type === "image"
          ? ".png"
          : previewData.type === "audio"
            ? ".mp3"
            : ".pdf";
    const filename = `Content-${previewData.type}-${previewData.id}${mimetype}`;
    const url = previewData.src;
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
        app.showToast("File Saved!", "success");
      })
      .catch((err) => {
        console.log(err);
        app.showToast("Failed to download please try again!", "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //Info Preview
  const [infoData, setInfoData] = useState({
    show: false,
    id: null,
    mode: null,
    data: [],
  });

  const resetInfoData = () => {
    setInfoData({
      show: false,
      id: null,
      mode: infoData.mode,
      data: [],
    });
  };

  const clickOnHotspot = async (event, hs) => {
    if (
      hs.icon_type === "image" ||
      hs.icon_type === "video" ||
      hs.icon_type === "pdf" ||
      hs.icon_type === "audio"
    ) {
      if (hs.createTooltipArgs?.path === null) {
        return false;
      }
      setPreviewData((prev) => {
        const old = { ...prev };
        old.show = true;
        old.id = hs.id;
        old.src = hs.createTooltipArgs.path;
        old.type = hs.icon_type;
        return { ...old };
      });
      if (hs.icon_type === "audio") {
        if (audioRef.current) {
          if (audioRef.current.src !== hs.createTooltipArgs.path) {
            audioRef.current.src = hs.createTooltipArgs.path;
          }
          if (!audioRef.current.paused) {
            await audioRef.current.pause();
          } else if (audioRef.current.paused) {
            audioRef.current.currentTime = 0;
            await audioRef.current.play();
          }
        }
      }
    } else if (hs.icon_type === "info") {
      const data = hs?.createTooltipArgs?.path?.length
        ? await JSON.parse(JSON.stringify(hs?.createTooltipArgs?.path))
        : await JSON.parse(JSON.stringify(infoData.data));
      const findIndex = data.findIndex((ele) => ele.type === "HTML");
      if (findIndex === -1) {
        setEditorState(EditorState.createEmpty());
      } else {
        let es = await JSON.parse(data[findIndex].data);
        es = EditorState.createWithContent(convertFromRaw(es));
        setEditorState(es);
      }
      setInfoData((prev) => {
        const old = { ...prev };
        old.show = true;
        old.id = hs.id;
        old.mode = "preview";
        old.data = [...data];
        return { ...old };
      });
    } else {
      if (!hs.sceneId) {
        return false;
      }
      if (
        event.target.className.includes("primary-btn") ||
        event.target.className.includes("icon-audio") ||
        event.target.className.includes("icon-image") ||
        event.target.className.includes("icon-video") ||
        event.target.className.includes("icon-comment") ||
        event.target.className.includes("icon-editRequest") ||
        event.target.className.includes("icon-pdf")
      ) {
        return;
      }
      props.setSelectedScene({
        ...props.tourConfig.scenes[hs.sceneId],
        pitch: hs.targetPitch,
        yaw: hs.targetYaw,
        hfov: hs.targetHfov,
      });
    }
  };

  const [customConfig, setCustomConfig] = useState(null);

  const generateTooltip = (hotSpotDiv, args, id, pitch, yaw, hotspots = []) => {
    if (!args.type) {
      return;
    }
    hotSpotDiv.tabIndex = id;
    hotSpotDiv.addEventListener("keypress", function (e) {
      if (e.key === "Enter") {
        hotSpotDiv.click();
      }
    });
    hotSpotDiv.addEventListener("keydown", function (e) {
      if (
        e.key === "ArrowDown" ||
        e.key === "ArrowUp" ||
        e.key === "ArrowLeft" ||
        e.key === "ArrowRight" ||
        e.key === "a" ||
        e.key === "s" ||
        e.key === "d" ||
        e.key === "w"
      ) {
        // hotSpotDiv.blur();
        // pannellumRef?.current?.focus();
        // pannellumRef?.current?.click();
        // document.querySelector('canvas').focus();
        // document.querySelector('canvas').click();
      }
    });
    hotSpotDiv.addEventListener("focus", function (e) {
      setCustomConfig({
        pitch: pitch,
        yaw: yaw,
      });
    });

    if (args.action === "scene" && args.title === "" && args.message === "") {
      hotSpotDiv.classList.remove("custom-tooltip");
      hotSpotDiv.innerHTML = "";
    } else {
      hotSpotDiv.classList.add("custom-tooltip");
      hotSpotDiv.innerHTML = "";
      var span = document.createElement("span");

      if (args.type === "image") {
        span.innerHTML = `
          <p class="tooltip-title tag-1">${args.title}</p>
          ${args?.path !== null
            ? `<img class="content-preview" src="${args.path}">`
            : ""
          }
          <p class="tooltip-desc tag-1">${args.message}</p>
        `;
        if (args.title === "" && args.message === "" && (args.path === null || !previewMode)) {
          hotSpotDiv.classList.remove("custom-tooltip");
          hotSpotDiv.innerHTML = "";
        }
      } else if (args.type === "video") {
        let isNotYTorVimeo = false;
        const info = urlParser.parse(args?.path || "www.google.com");
        if (!info) {
          isNotYTorVimeo = true;
        } else if (
          (info?.provider !== "vimeo" && info?.provider !== "youtube") ||
          info?.mediaType !== "video"
        ) {
          isNotYTorVimeo = true;
        } else {
          isNotYTorVimeo = info.provider;
        }
        let videoHtml = `<video class="content-preview" width="200" height="120" controls> 
          <source src="${args.path}" type="video/mp4">
        </video>`;
        if (isNotYTorVimeo === "vimeo") {
          const id = args?.path?.replace("https://vimeo.com/", "");
          videoHtml = `<iframe class="content-preview" src="https://player.vimeo.com/video/${id}?h=c6db007fe5&color=ef0800&title=0&byline=0&portrait=0" 
        width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
        }
        if (isNotYTorVimeo === "youtube") {
          videoHtml = `<iframe class="content-preview" width="100%" height="100%" src=${args.path} 
        title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
        </iframe>`;
        }
        span.innerHTML = `
        <p class="tooltip-title tag-1">${args.title}</p>
        ${args?.path !== null ? videoHtml : ""}
          <p class="tooltip-desc tag-1">${args.message}</p>
        `;
        if (args.title === "" && args.message === "" && (args.path === null || !previewMode)) {
          hotSpotDiv.classList.remove("custom-tooltip");
          hotSpotDiv.innerHTML = "";
        }
      } else if (args.type === "audio") {
        span.innerHTML = `
        <p class="tooltip-title tag-1">${args.title}</p>
          <p class="tooltip-desc tag-1">${args.message}</p>
        `;
        if (args.title === "" && args.message === "") {
          hotSpotDiv.classList.remove("custom-tooltip");
          hotSpotDiv.innerHTML = "";
        }
      } else if (args.type === "pdf") {
        span.innerHTML = `
        <p class="tooltip-title tag-1">${args.title}</p>
        ${args?.path !== null
            ? `<iframe
            class="content-preview"
            src="${args.path}#toolbar=0&navpanes=0&scrollbar=0"
            frameBorder="0"
            scrolling="auto"
            height="100%"
            width="100%"
            ></iframe>`
            : ""
          }
        <p class="tooltip-desc tag-1">${args.message}</p>
        `;
        if (args.title === "" && args.message === "" && (args.path === null || !previewMode)) {
          hotSpotDiv.classList.remove("custom-tooltip");
          hotSpotDiv.innerHTML = "";
        }
      } else if (args.type === "url") {
        if (args.title !== "" || args.message !== "") {
          span.innerHTML = `
          <p class="tooltip-title tag-1">${args.title}</p>
          <a href="${args.message}" target="_blank" class="tooltip-desc tag-1">${args.message}</a>
        `;
        } else {
          hotSpotDiv.classList.remove("custom-tooltip");
          hotSpotDiv.innerHTML = "";
        }
      } else if (args.type === "info") {
        if (args.title !== "" || args.message !== "") {
          span.innerHTML = `
          <p class="tooltip-title tag-1">${args.title}</p>
          <p class="tooltip-desc tag-1">${args.message}</p>
        `;
        } else {
          hotSpotDiv.classList.remove("custom-tooltip");
          hotSpotDiv.innerHTML = "";
        }
      } else if (args.type === "editRequest") {
        const node = document.createElement("div");
        const hotspotId = hotSpotDiv.getAttribute("hotspot-id");
        const commentsArr = hotspots.filter(
          (hs) => hs.icon_type === "editRequest"
        );
        const hotspotIndex = commentsArr.findIndex(
          (hotspot) => hotspot.id === hotspotId
        );
        node.style.color = "white";
        node.style.width = "100%";
        node.style.height = "100%";
        node.style.display = "flex";
        node.style.justifyContent = "center";
        node.style.alignItems = "center";
        node.innerHTML = hotspotIndex + 1;
        hotSpotDiv.appendChild(node);
        hotSpotDiv.classList.add("edit-request");
        const hotSpotID = `unique-post-comment-button-${addedHotspot}`;
        const deleteSpotID = `unique-post-delete-button-${addedHotspot}`;
        const inputID = `unique-post-comment-input-${addedHotspot}`;
        const selectID = `unique-post-comment-select-${addedHotspot}`;
        span.innerHTML =
          `
        <div style="padding: 5px 0;" class='edit-request-block'>
          <textarea placeholder="Comment" id="` +
          inputID +
          `" ` +
          `${args.disabled ? "disabled='true'" : ""}` +
          ` class='editRequest-input' >${args.title}</textarea/>
          <select id="` +
          selectID +
          `" ` +
          `${args.disabled ? "disabled='true'" : ""}` +
          ` class='input-select-edit-request' value='` +
          args.message +
          `' >
            <option value="">Select Request Type</option>
            <option ${args.message === "Minimum requirement" ? "selected" : ""
          } value="Minimum requirement">Minimum requirement</option>
            <option ${args.message === "Additional request" ? "selected" : ""
          } value="Additional request">Additional request</option>
            <option ${args.message === "CGI related" ? "selected" : ""
          } value="CGI related">CGI related</option>
            <option ${args.message === "Above and beyond" ? "selected" : ""
          } value="Above and beyond">Above and beyond</option>
          </select>
          <div style="display: flex;flex-direction: row-reverse;align-items: center;">
            <button variant="contained" type="button" id="` +
          deleteSpotID +
          `" color="primary" class="secondary-btn btn-edit-request MuiButton-root MuiButton-containedPrimary MuiButton-contained">
              ${!args.disabled ? "Cancel" : "Delete"}
            </button>
            ${!args.disabled
            ? `<button style="margin-right: 10px;" variant="contained" id="` +
            hotSpotID +
            `" type="submit" color="primary" class="primary-btn btn-edit-request MuiButton-root MuiButton-containedPrimary MuiButton-contained">
              Post
            </button>`
            : ""
          }
          </div>
        </div>
      `;
      } else {
        if (
          args.action === "scene" &&
          (args.title !== "" || args.message !== "")
        ) {
          span.innerHTML = `
            <p class="tooltip-title tag-1">${args.title}</p>
            <p class="tooltip-desc tag-1">${args.message}</p>
          `;
        }
      }
      hotSpotDiv.appendChild(span);
      span.style.width = span.scrollWidth - 20 + "px";
      span.style.marginLeft =
        -(span.scrollWidth - hotSpotDiv.offsetWidth) / 2 + "px";
      span.style.marginTop = -span.scrollHeight - 12 + "px";
      span.style.marginBottom = 22 + "px";
    }
  };

  useEffect(() => {
    if (!props.selectedScene) {
      return;
    }
    panEditor && panEditor.destroy();
    const tourConfig = {
      ...props.tourConfig,
      firstScene: props.selectedScene.sceneId,
    };
    if (tourConfig && tourConfig.scenes) {
      Object.keys(tourConfig.scenes).map((sceneId) => {
        tourConfig.scenes[sceneId].hotSpots.map((hotspot) => {
          const tooltipArgs =
            hotspot && hotspot.createTooltipArgs
              ? hotspot.createTooltipArgs
              : {};
          let scene = tooltipArgs && tooltipArgs.scene ? tooltipArgs.scene : {};

          const targetSceneView = tooltipArgs.targetSceneView
            ? tooltipArgs.targetSceneView
            : {};
          if (targetSceneView) {
            hotspot.targetPitch = targetSceneView.targetPitch;
            hotspot.targetHfov = targetSceneView.targetHfov;
            hotspot.targetYaw = targetSceneView.targetYaw;
          }

          if (hotspot && (hotspot?.sceneId || scene._id)) {
            hotspot.clickHandlerFunc = clickOnHotspot;
          }
          if (
            hotspot.icon_type === "image" ||
            hotspot.icon_type === "video" ||
            hotspot.icon_type === "pdf" ||
            hotspot.icon_type === "audio" ||
            hotspot.icon_type === "info"
          ) {
            hotspot.clickHandlerFunc = clickOnHotspot;
          }
          hotspot.createTooltipFunc = (ht, args) => {
            generateTooltip(
              ht,
              args,
              hotspot.id,
              hotspot.pitch,
              hotspot.yaw,
              props.tourConfig.scenes[sceneId].hotSpots
            );
          };
          if (tooltipArgs) {
            hotspot.icon_type = tooltipArgs.type;
          }
          if (scene && scene._id) {
            hotspot.type = "action";
            hotspot.sceneId = scene._id;
          }
        });
      });
    }
    tourConfig.draggable = props.isFlatView ? false : true;
    tourConfig.haov = props.isFlatView ? 120 : 360;
    tourConfig.vaov = props.isFlatView ? 110 : 180;
    // tourConfig.flatView = true;
    tourConfig.sceneFadeDuration = 1000;
    tourConfig.hfov = 120;
    tourConfig.disableKeyboardCtrl = false;
    const panEditor2 = pannellum.viewer(props.id, tourConfig);
    setPanEditor(panEditor2);
    panEditor2.on("load", () => { });
  }, []);

  useEffect(() => {
    if (!props.selectedScene) {
      return;
    }
    setCustomConfig(null);
    if (panEditor && props.selectedScene.sceneId) {
      let panoEditor = panEditor.loadScene(
        props.selectedScene.sceneId,
        props.selectedScene.pitch || 0,
        props.selectedScene.yaw || 0,
        props.selectedScene.hfov || 120
      );
      panoEditor.on("load", () => { });
      setPanEditor(panoEditor);
    }
  }, [props.selectedScene]);

  useEffect(() => {
    if (!customConfig) {
      return;
    }
    if (panEditor && props.selectedScene.sceneId) {
      panEditor.lookAt(
        customConfig.pitch,
        customConfig.yaw,
        customConfig.hfov || 120,
        1000
      );
    }
  }, [customConfig]);

  const [isNotYTorVimeoG, setIsNotYTorVimeoG] = useState(false);
  useEffect(() => {
    if (previewData.src === null) {
      return false;
    }
    let isNotYTorVimeo = false;
    const info = urlParser.parse(previewData.src);
    if (!info) {
      isNotYTorVimeo = true;
    } else if (
      (info?.provider !== "vimeo" && info?.provider !== "youtube") ||
      info?.mediaType !== "video"
    ) {
      isNotYTorVimeo = true;
    } else {
      isNotYTorVimeo = info.provider;
    }
    setIsNotYTorVimeoG(isNotYTorVimeo);
  }, [previewData.src]);

  // Media Renderer
  const [type, setType] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  function mediaBlockRenderer(block) {
    let obj = null;
    if (block.getType() === "atomic") {
      obj = {
        component: Media,
        editable: false,
      };
    }

    return obj;
  }

  const Audio = (props) => {
    return <audio controls src={props.src} />;
  };

  const Image = (props) => {
    return <img src={props.src} alt={"img"} style={{ width: "100%" }} />;
  };

  const Video = (props) => {
    return <video controls src={props.src} style={{ width: "100%" }} />;
  };

  const Media = (props) => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0));
    const { src } = entity.getData();
    const type = entity.getType();
    let media = null;
    switch (type) {
      case "audio":
        media = <Audio src={src} />;
        break;
      case "image":
        media = <Image src={src} />;
        break;
      case "video":
        media = <Video src={src} />;
        break;
      default:
        media = null;
        break;
    }
    return media;
  };

  return (
    <>
      {isLoading && <CircularProgress color="primary" size={40} />}
      <div
        ref={pannellumRef}
        className={`pannellum-wrapper ${previewMode ? "preview-on" : "preview-off"
          }`}
      >
        {Object.keys(props.selectedScene).length !== 0 && (
          <div className="tour-360-display">
            <div id={props.id}></div>
          </div>
        )}
        <audio
          style={{ opacity: "0", visibility: "hidden", display: "none" }}
          ref={audioRef}
          controls
        >
          <source src={previewData.src} type="audio/mp3" />
        </audio>
      </div>
      <Dialog
        open={
          previewData.show &&
          (previewData.type === "image" ||
            previewData.type === "video" ||
            previewData.type === "pdf")
        }
        className="no-max-width bg-black"
      >
        {isLoading && <CircularProgress color="primary" size={40} />}
        <DialogActions>
          {!(isNotYTorVimeoG === "youtube" || isNotYTorVimeoG === "vimeo") && (
            <Tooltip title={"Download"}>
              <span style={{ margin: "0 5px" }}>
                <IconButton
                  size="small"
                  onClick={downloadCurrentData}
                  style={{ backgroundColor: "#8F0339", padding: "8px" }}
                  className="tool-icon-btn btn-download"
                >
                  <GetAppIcon style={{ color: "white" }} />
                </IconButton>
              </span>
            </Tooltip>
          )}
          <Tooltip title="Close">
            <span style={{ margin: "0 5px" }}>
              <IconButton
                size="small"
                onClick={resetPreviewData}
                style={{ backgroundColor: "#8F0339", padding: "8px" }}
                className="tool-icon-btn btn-download"
              >
                <CloseIcon style={{ color: "white" }} />
              </IconButton>
            </span>
          </Tooltip>
        </DialogActions>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div
              className={`${previewData.type !== "audio" ? "data-preview" : ""
                }`}
            >
              {previewData.type === "video" ? (
                <VideoRender src={previewData.src} />
              ) : previewData.type === "pdf" ? (
                <div style={{ width: "100%", height: "700px" }}>
                  <PDFReader showAllPage={true} url={previewData.src} />
                </div>
              ) : previewData.type === "audio" ? (
                <audio controls>
                  <source src={previewData.src} type="audio/mp3" />
                </audio>
              ) : (
                <div style={{ width: "100%", height: "600px" }}>
                  <ProgImage
                    src={previewData.src}
                    preview={""}
                    showLoader={true}
                  />
                </div>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog open={infoData.show} className="no-max-width bg-black">
        {isLoading && <CircularProgress color="primary" size={40} />}
        <DialogActions>
          <Tooltip title={"Close"}>
            <span style={{ margin: "0 5px" }}>
              <IconButton
                size="small"
                onClick={resetInfoData}
                style={{ backgroundColor: "#8F0339", padding: "8px" }}
                className="tool-icon-btn btn-download"
              >
                <CloseIcon style={{ color: "white" }} />
              </IconButton>
            </span>
          </Tooltip>
        </DialogActions>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className={`${"data-preview"}`}>
              {infoData?.data?.map((content, index) => {
                switch (content.type) {
                  case "audio":
                    return (
                      <div key={index} style={{ marginBottom: "20px" }}>
                        {content.data !== "N/A" && (
                          <audio controls>
                            <source src={content.data} type="audio/mp3" />
                          </audio>
                        )}
                      </div>
                    );
                  case "video":
                    return (
                      <div key={index} style={{ marginBottom: "20px" }}>
                        {content.data !== "N/A" && (
                          <video
                            style={{ width: "100%", height: "auto" }}
                            controls
                          >
                            <source src={content.data} type="video/mp4" />
                          </video>
                        )}
                      </div>
                    );
                  case "image":
                    return (
                      <div key={index} style={{ marginBottom: "20px" }}>
                        {content.data !== "N/A" && (
                          <div style={{ width: "100%", height: "600px" }}>
                            <ProgImage
                              src={content.data}
                              preview={""}
                              showLoader={true}
                            />
                          </div>
                        )}
                      </div>
                    );
                  case "text":
                    return (
                      <div key={index} style={{ marginBottom: "20px" }}>
                        {content.data && content.data.trim().length > 0 && (
                          <p
                            style={{
                              textTransform: "capitalize",
                              fontWeight: "600",
                              fontSize: "16px",
                              color: "white",
                            }}
                          >
                            {content.data}
                          </p>
                        )}
                      </div>
                    );
                  case "HTML":
                    return (
                      <div
                        key={index}
                        className="fullEditor"
                        style={{ marginBottom: "20px" }}
                      >
                        <Editor
                          editorState={editorState}
                          blockRendererFn={mediaBlockRenderer}
                          toolbarClassName="toolbarClassName hidden"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName fullEditor"
                          toolbar={{
                            inline: {
                              inDropdown: true,
                              options: [
                                "bold",
                                "italic",
                                "underline",
                                "strikethrough",
                              ],
                            },
                            list: {
                              inDropdown: true,
                              options: ["unordered", "ordered"],
                            },
                            link: {
                              inDropdown: true,
                              showOpenOptionOnHover: true,
                              defaultTargetOption: "_self",
                              options: ["link", "unlink"],
                            },
                            image: {
                              urlEnabled: true,
                              uploadEnabled: true,
                              alignmentEnabled: true,
                              previewImage: false,
                              inputAccept:
                                "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                              alt: { present: false, mandatory: false },
                              defaultSize: {
                                height: "auto",
                                width: "auto",
                              },
                            },
                          }}
                        />
                      </div>
                    );
                  default:
                    return null;
                }
              })}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog open={infoData.show} className="no-max-width bg-black">
        <DialogActions>
          <Tooltip title={infoData.mode === "edit" ? "Cancel" : "Close"}>
            <span style={{ margin: "0 5px" }}>
              <IconButton
                size="small"
                onClick={resetInfoData}
                style={{ backgroundColor: "#8F0339", padding: "8px" }}
                className="tool-icon-btn btn-download"
              >
                <CloseIcon style={{ color: "white" }} />
              </IconButton>
            </span>
          </Tooltip>
        </DialogActions>
        <DialogContent style={{ padding: "12px", paddingTop: "0" }}>
          <DialogContentText id="alert-dialog-description">
            <div
              className={`${infoData.mode === "edit" ? "data-edit" : "data-preview"
                }`}
            >
              {renderPreviewData(
                infoData?.data,
                editorState,
                mediaBlockRenderer
              )}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

const VideoRender = ({ src = "www.google.com" }) => {
  if (src === null) {
    return <></>;
  }
  let isNotYTorVimeo = false;
  const info = urlParser.parse(src);
  if (!info) {
    isNotYTorVimeo = true;
  } else if (
    (info?.provider !== "vimeo" && info?.provider !== "youtube") ||
    info?.mediaType !== "video"
  ) {
    isNotYTorVimeo = true;
  } else {
    isNotYTorVimeo = info.provider;
  }
  if (isNotYTorVimeo === "vimeo") {
    const id = src.replace("https://vimeo.com/", "");
    return (
      <iframe
        src={`https://player.vimeo.com/video/${id}?h=c6db007fe5&color=ef0800&title=0&byline=0&portrait=0`}
        width="100%"
        height="500"
        frameborder="0"
        title="Vemio Player"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
      ></iframe>
    );
  }
  if (isNotYTorVimeo === "youtube") {
    return (
      <iframe
        width="100%"
        height="500"
        src={src}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    );
  }
  return (
    <video width="100%"
      height="500" controls>
      <source src={src} type="video/mp4" />
    </video>
  );
};

function renderPreviewData(data, editorState, mediaBlockRenderer) {
  const textData = (data || []).filter(
    (item) => item.type === "HTML" || item.type === "text"
  );
  const others = (data || []).filter(
    (item) => item.type !== "HTML" && item.type !== "text"
  );
  const allData = [...textData, ...others];
  return allData.map((content, index) => {
    switch (content.type) {
      case "audio":
        return (
          <div key={index} style={{ marginBottom: "20px" }}>
            {content.data !== "N/A" && (
              <audio controls>
                <source src={content.data} type="audio/mp3" />
              </audio>
            )}
          </div>
        );
      case "video":
        let isNotYTorVimeo = false;
        const info = urlParser.parse(content.data);
        if (!info) {
          isNotYTorVimeo = true;
        } else if (
          (info?.provider !== "vimeo" && info?.provider !== "youtube") ||
          info?.mediaType !== "video"
        ) {
          isNotYTorVimeo = true;
        } else {
          isNotYTorVimeo = info.provider;
        }
        let VideoHtml = (
          <video width="200" height="120" controls>
            <source src={content.data} type="video/mp4" />
          </video>
        );
        if (isNotYTorVimeo === "vimeo") {
          VideoHtml = (
            <iframe
              src="https://player.vimeo.com/video/253989945?h=c6db007fe5&color=ef0800&title=0&byline=0&portrait=0"
              width="100%"
              height="100%"
              frameborder="0"
              title="Vemio Player"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          );
        }
        if (isNotYTorVimeo === "youtube") {
          VideoHtml = (
            <iframe
              width="100%"
              height="100%"
              src={content.data}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          );
        }
        return (
          <div key={index} style={{ marginBottom: "20px" }}>
            {content.data !== "N/A" && <VideoHtml />}
          </div>
        );
      case "image":
        return (
          <div key={index} style={{ marginBottom: "20px" }}>
            {content.data !== "N/A" && (
              <div style={{ width: "100%", height: "600px" }}>
                <ProgImage src={content.data} preview={""} showLoader={true} />
              </div>
            )}
          </div>
        );
      case "text":
        return (
          <div key={index} style={{ marginBottom: "20px" }}>
            {content.data && content.data.trim().length > 0 && (
              <p
                style={{
                  textTransform: "capitalize",
                  fontWeight: "600",
                  fontSize: "16px",
                  color: "white",
                }}
              >
                {content.data}
              </p>
            )}
          </div>
        );
      case "HTML":
        return (
          <div key={index} style={{ marginBottom: "20px" }}>
            <Editor
              editorState={editorState}
              blockRendererFn={mediaBlockRenderer}
              toolbarClassName="toolbarClassName hidden"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName fullEditor"
              toolbar={{
                inline: {
                  inDropdown: true,
                  options: ["bold", "italic", "underline", "strikethrough"],
                },
                list: {
                  inDropdown: true,
                  options: ["unordered", "ordered"],
                },
                link: {
                  inDropdown: true,
                  showOpenOptionOnHover: true,
                  defaultTargetOption: "_self",
                  options: ["link", "unlink"],
                },
                image: {
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  previewImage: false,
                  inputAccept:
                    "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                  alt: { present: false, mandatory: false },
                  defaultSize: {
                    height: "auto",
                    width: "auto",
                  },
                },
              }}
            />
          </div>
        );
      default:
        return null;
    }
  });
}

export default VmtttPanoromaViewer;
