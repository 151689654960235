import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Tour360 from './pages/Tour360/Tour360';
import './App.css';
import Scene360 from './pages/Scene360/Scene360';
// import { useFlags } from 'launchdarkly-react-client-sdk';

function App() {
  const [configureVisual, setConfigureVisual] = useState({
    fontSize: 1
  });

  // const flags = useFlags();
  // console.log('flags', flags)

  const onConfigureChangeHandler = (key, newValue) => {
    setConfigureVisual(prev => {
      const old = { ...prev };
      old[key] = newValue;
      return { ...old };
    })
  };

  return (
    <Router>
      <Routes>
        <Route path="/:id" element={<Tour360 configureVisual={configureVisual} onConfigureChangeHandler={onConfigureChangeHandler} />} />
        <Route path="/t/:id/s/:sceneId" element={<Tour360 configureVisual={configureVisual} onConfigureChangeHandler={onConfigureChangeHandler} />} />
        <Route path="/scene/:id" element={<Scene360 />} />
      </Routes>
    </Router>
  )
}

export default App;
