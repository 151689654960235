import { Slider, Tooltip } from '@mui/material';
import React, { useCallback, useEffect } from 'react'
import { useState } from 'react';
import { ReactComponent as VolumeUpIcon } from '../../assets/images/hotspot-icons/icon-audio.svg';
import "./ADASoundController.scss";
const ADASoundController = ({ isPlaying, audioRef, setIsPlaying }) => {

    const toggleSetIsPlaying = useCallback((state) => {
        setIsPlaying(state);
    }, [setIsPlaying]);

    useEffect(() => {
        if (!audioRef) {
            return;
        }
        if (audioRef?.current?.paused) {
            toggleSetIsPlaying(false);
        } else {
            toggleSetIsPlaying(true);
        }
    }, [audioRef?.current?.paused, toggleSetIsPlaying, audioRef]);

    const [volume, setVolumne] = useState(50);

    const increaseVolumne = () => {
        setVolumne(prev => {
            return prev + 10 > 100 ? 100 : prev + 10
        })
    };

    const decreaseVolumne = () => {
        setVolumne(prev => {
            return prev - 10 < 0 ? 0 : prev - 10
        })
    };

    const changeHanlder = (e) => {
        setVolumne(e.target.value);
    };

    useEffect(() => {
        if (audioRef?.current) {
            audioRef.current.volume = volume / 100;
        }
    }, [volume, audioRef]);

    return (
        <div className='soundController' tabIndex="1001" onKeyUp={(e) => {
            if (e.code === "ArrowUp") {
                increaseVolumne();
            } else if (e.code === "ArrowDown") {
                decreaseVolumne();
            }
        }}>
            <Tooltip title={`Sound ${isPlaying ? 'Controller' : "Controller"}`}>
                <VolumeUpIcon className={isPlaying ? 'colored-icon' : ''} />
            </Tooltip>
            <div className='soundController-slider'>
                <Slider orientation="vertical" aria-labelledby="vertical-slider" max={100} min={0} value={volume} onChange={changeHanlder} valueLabelDisplay="on" step={1} className="zoom-slider" color="secondary" />
            </div>
        </div>
    )
};
//value={configureVisual.fontSize}
//onChange={handlerChange} 
export default ADASoundController